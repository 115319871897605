export const enum BGColors {
    mint = 'rgb(239, 252, 237)',
    grey = 'rgb(233, 237, 244)',
    skyBlue = 'rgb(217, 252, 252)',
    violet = 'rgb(137, 169, 226)',
}

export const IFRAME_STYLES = Object.freeze({width: '100%',border: 'none'});

export const enum AgeGateCookieEnum {
    CookieName = 'ageCheck',
    ConsentAllValue = 'a',
    ConsentReqValue = 'r',
    CookieDuration = 3600 * 24 * 30, // 30 days, in seconds
}