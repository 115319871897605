import React from "react";
import {ReactCookieProps, useCookies} from "react-cookie";
import {AgeGateCookieEnum} from "../utilities/enums_and_constants";

export interface RootState {
    showAgeGate: boolean;
    acceptAgeGate: (rememberMe: boolean, allowAll: boolean) => void;
}

export const RootContext = React.createContext<RootState>({
    showAgeGate: true,
    acceptAgeGate: (_) => {
    },
});
export default RootContext;


/**
 * Initializes the Google tag by checking for `utm_X` URL parameters.  If any are found,
 * they are set globally in the tag.
 *
 * @param status {'granted' | 'denied' | null} If `null`, will not fire `setTag`.  Otherwise, passes
 * value to `setTag` to actively set user's consent value for cookie-related storage.
 *
 * @returns {void}
 */
function initGTag(status: 'granted' | 'denied' | null): void {
    if (!!status) setTag(status);
    if ('gtag' in window && typeof window.gtag === 'function') {

        const params = new URLSearchParams(window.location.search);
        const campaignValues: Record<string, string> = {};

        // Ref: https://support.google.com/analytics/answer/10917952?hl=en
        const campaign_id = params.get('utm_id');
        const campaign_name = params.get('utm_campaign');
        const campaign_medium = params.get('utm_medium');
        const campaign_source = params.get('utm_source');

        if (!!campaign_id) campaignValues[campaign_id] = campaign_id;
        if (!!campaign_name) campaignValues[campaign_name] = campaign_name;
        if (!!campaign_medium && !!campaign_source) {
            // Ref: https://support.google.com/analytics/answer/11259997?hl=en
            // "medium" and "source" are required.  Without these, nothing can be set.
            // There's a URL builder here: https://ga-dev-tools.google/ga4/campaign-url-builder/
            campaignValues[campaign_medium] = campaign_medium;
            campaignValues[campaign_source] = campaign_source;
            window.gtag('set', campaignValues);
        }
    }
}

/**
 * Wrapper for the Google Tag interface to grant/deny permission to use storage for Google Analytics tracking.
 *
 * @param status {('granted' | 'denied')} - Passed to `gtag`'s consent setting for all storage options.
 * @returns {void}
 * */
function setTag(status: 'granted' | 'denied'): void {
    // Check that `gtag` exists on the window.  If so, update the consent status with
    // either `granted` or `denied` as appropriate.
    if ('gtag' in window && typeof window.gtag === 'function') {
        window.gtag('consent', 'update', {
            ad_storage: status,
            analytics_storage: status,
        });
    }
}

export const RootProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [cookies, setCookies] = useCookies([AgeGateCookieEnum.CookieName]);
    const [showAgeGate, setShowAgeGate] = React.useState(
        ![AgeGateCookieEnum.ConsentAllValue, AgeGateCookieEnum.ConsentReqValue].includes(cookies.ageCheck)
    );

    /**
     * Callback for the `AgeGateComponent` which handles setting the appropriate cookie values.  Gets passed through
     * the RootContext.Consumer into the <AgeGateComponent />.
     *
     * @param rememberMe {boolean} - If `false`, the age-gate cookie will be created as a Session cookie (i.e. expires
     * on browser close).  If `true`, then the cookie will have a `maxAge` defined by the `AgeGateCookieEnum.CookieDuration`
     * value.
     *
     * @param allowAll {boolean} - If `true`, will consider cookie consent as "allow all" and thus will grant Google
     * Tag storage access.  If `false`, the user consents to required cookies _only_, and thus will set GTag storage
     * access to `denied.`
     * */
    function acceptAgeGate(rememberMe: boolean, allowAll: boolean): void {
        // Init without `expires` or `maxAge`, so the default cookies are session-based.
        // If the user has selected `rememberMe`, then set a `maxAge`.
        const baseOptions: ReactCookieProps['defaultSetOptions'] = {
            domain: window.location.hostname.split('.').filter(s => s !== 'www').join('.'),
            path: '/',
            sameSite: 'lax',
        }

        if (rememberMe) baseOptions.maxAge = AgeGateCookieEnum.CookieDuration as NonNullable<ReactCookieProps['defaultSetOptions']>['maxAge'];
        setCookies(AgeGateCookieEnum.CookieName, allowAll ? AgeGateCookieEnum.ConsentAllValue : AgeGateCookieEnum.ConsentReqValue, baseOptions);

        // Update the gTag based on the response.
        setTag(allowAll ? 'granted' : 'denied');

        setShowAgeGate(false);
    }


    const providerState: RootState = {
        showAgeGate,
        acceptAgeGate: acceptAgeGate.bind(this)
    };

    // Run this in `useEffect` to ensure it's on the client site (and thus `window` exists).
    React.useEffect(() => {
        const initValue = [AgeGateCookieEnum.ConsentAllValue, AgeGateCookieEnum.ConsentReqValue].includes(cookies.ageCheck)
            ? cookies.ageCheck === AgeGateCookieEnum.ConsentAllValue ? 'granted' : "denied"
            : null; // Prevents setTag from being fired if the AgeGateCookie isn't set yet.

        initGTag(initValue);
    }, []);

    return <RootContext.Provider value={providerState}>
        {props.children}
    </RootContext.Provider>
}
